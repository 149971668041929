import React, { FC } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { st, classes } from './Card.st.css';

export const Card: FC<{ displayAllContent: boolean }> = ({ displayAllContent, children }) => {
  const settings = useSettings();

  const isSectionHeightEnabled = settings.get(settingsParams.isSectionHeightEnabled);
  const shouldWrap = isSectionHeightEnabled && !displayAllContent;

  return shouldWrap ? <div className={st(classes.card)}>{children}</div> : <>{children}</>;
};
