import { StyleParamType, createStylesParams } from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  numSectionHeight: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  numSectionHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 190,
  },
});
