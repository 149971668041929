import { DraftContent } from '@wix/ricos-viewer';
import { setupContentBuilder } from 'ricos-content/libs/Content';
import type { RichContent } from 'ricos-schema';

export const generateKey = () => Math.random().toString(36).substring(2, 9);

export const getEmptyStateContent = (title: string, label: string): DraftContent => ({
  blocks: [
    {
      key: '621er',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ir',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '627er',
      text: title,
      type: 'header-two',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '657ir',
      text: label,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '621et',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ie',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
  VERSION: '8.3.0',
});

export const getEmptyContent = (title: string, label: string): RichContent => {
  const api = setupContentBuilder(generateKey);

  return {
    nodes: [
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addHeading({
        text: title,
        content: { nodes: [] },
        // This any is needed to avoid importing TextStyle_TextAlignment enum from ricos-schema
        data: { level: 2, textStyle: { textAlignment: 'CENTER' as any } },
      }).nodes,
      ...api.addParagraph({
        text: label,
        content: { nodes: [] },
        data: { textStyle: { textAlignment: 'CENTER' as any } },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
    ],
    metadata: {
      version: 1,
      createdTimestamp: new Date('2022-12-11T21:02:58.993Z'),
      updatedTimestamp: new Date('2022-12-11T21:02:58.993Z'),
      id: 'cd6505af-e738-4c10-b759-05659d7c8083',
    },
    documentStyle: {},
  };
};
