import {
  StyleParamType,
  createStylesParams,
  wixColorParam,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  buttonStyle: StyleParamType.Number;
  buttonTextColor: StyleParamType.Color;
  buttonBgColor: StyleParamType.Color;
  buttonBorderColor: StyleParamType.Color;
  buttonBorderWidth: StyleParamType.Number;
  buttonCornerRadius: StyleParamType.Number;
  buttonHoverTextColor: StyleParamType.Color;
  buttonHoverBgColor: StyleParamType.Color;
  buttonHoverBorderColor: StyleParamType.Color;
  actionColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  buttonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  buttonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonHoverBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  actionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});
