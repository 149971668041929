import { merge } from 'lodash';
import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';
import backgroundStylesParams from './Tabs/Design/Background/stylesParams';
import textsStylesParams from './Tabs/Design/Texts/stylesParams';
import buttonLinksStylesParams from './Tabs/Design/ButtonLinks/stylesParams';
import displayStylesParams from './Tabs/Display/stylesParams';

type IPaletteStylesParams = {
  disabledColor: StyleParamType.Color;
  textColorLow: StyleParamType.Color;
  textColor: StyleParamType.Color;
};

const paletteStylesParams = createStylesParams<IPaletteStylesParams>({
  disabledColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  textColorLow: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

const stylesParams = merge(
  paletteStylesParams,
  backgroundStylesParams,
  textsStylesParams,
  buttonLinksStylesParams,
  displayStylesParams,
);

export default stylesParams;
