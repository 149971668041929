import {
  IStylesContextValue,
  ISettingsFont,
  ISettingsColor,
} from '@wix/yoshi-flow-editor/tpa-settings';
import stylesParams from '../stylesParams';

type Fonts = {
  type: string;
  font: ISettingsFont;
  color: ISettingsColor;
}[];

const getPalette = (styles: IStylesContextValue) => ({
  bgColor: styles.get(stylesParams.bgColor).value,
  textColor: styles.get(stylesParams.textColor).value,
  actionColor: styles.get(stylesParams.actionColor).value,
  textColorLow: styles.get(stylesParams.textColorLow).value,
  disabledColor: styles.get(stylesParams.disabledColor).value,
});

const getCustomStyles = (styles: IStylesContextValue) => {
  const getStyleFromStyleParam = (fontStyleParam: any, colorStyleParam: any): any => ({
    fontFamily: fontStyleParam.family,
    fontSize: `${fontStyleParam.size}px`,
    ...(colorStyleParam?.value && { color: colorStyleParam?.value }),
    ...(fontStyleParam?.style?.bold && { fontWeight: 'bold' }),
    ...(fontStyleParam?.style?.italic && { fontStyle: 'italic' }),
    ...(fontStyleParam?.style?.underline && { textDecoration: 'underline' }),
  });

  const fonts: Fonts = [
    {
      type: 'p',
      font: styles.get(stylesParams.paragraphTextFont),
      color: styles.get(stylesParams.paragraphTextColor),
    },
    {
      type: 'quote',
      font: styles.get(stylesParams.quoteTextFont),
      color: styles.get(stylesParams.quoteTextColor),
    },
    {
      type: 'h1',
      font: styles.get(stylesParams.headerOneTextFont),
      color: styles.get(stylesParams.headerOneTextColor),
    },
    {
      type: 'h2',
      font: styles.get(stylesParams.headerTwoTextFont),
      color: styles.get(stylesParams.headerTwoTextColor),
    },
    {
      type: 'h3',
      font: styles.get(stylesParams.headerThreeTextFont),
      color: styles.get(stylesParams.headerThreeTextColor),
    },
    {
      type: 'h4',
      font: styles.get(stylesParams.headerFourTextFont),
      color: styles.get(stylesParams.headerFourTextColor),
    },
    {
      type: 'h5',
      font: styles.get(stylesParams.headerFiveTextFont),
      color: styles.get(stylesParams.headerFiveTextColor),
    },
    {
      type: 'h6',
      font: styles.get(stylesParams.headerSixTextFont),
      color: styles.get(stylesParams.headerSixTextColor),
    },
  ];

  const customStyles: Record<string, any> = {};
  fonts.forEach(({ type, font, color }) => {
    if (font) {
      customStyles[type] = getStyleFromStyleParam(font, color);
    }
  });

  return customStyles;
};

export const getTheme = (styles: IStylesContextValue, parentClass: string) => ({
  palette: getPalette(styles),
  parentClass,
  customStyles: getCustomStyles(styles),
});
