import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

/*
  mappings:
  H1 == “Title”
  H2 == “Page-title”
  H3 == “Heading-XL”
  H4 == “Heading-L”
  H5 == “Heading-M”
  H6 == “Heading-S”
  P == “Body-M”
*/

type IStylesParams = {
  headerOneTextFont: StyleParamType.Font;
  headerOneTextColor: StyleParamType.Color;
  headerTwoTextFont: StyleParamType.Font;
  headerTwoTextColor: StyleParamType.Color;
  headerThreeTextFont: StyleParamType.Font;
  headerThreeTextColor: StyleParamType.Color;
  headerFourTextFont: StyleParamType.Font;
  headerFourTextColor: StyleParamType.Color;
  headerFiveTextFont: StyleParamType.Font;
  headerFiveTextColor: StyleParamType.Color;
  headerSixTextFont: StyleParamType.Font;
  headerSixTextColor: StyleParamType.Color;
  paragraphTextFont: StyleParamType.Font;
  paragraphTextColor: StyleParamType.Color;
  quoteTextFont: StyleParamType.Font;
  quoteTextColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  headerOneTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Title', {
        size: isMobile ? 32 : 40,
      })({ textPresets }),
  },
  headerOneTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTwoTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Page-title', {
        size: isMobile ? 24 : 28,
      })({ textPresets }),
  },
  headerTwoTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerThreeTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Heading-XL', {
        size: isMobile ? 20 : 24,
      })({ textPresets }),
  },
  headerThreeTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerFourTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Heading-L', {
        size: 20,
      })({ textPresets }),
  },
  headerFourTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerFiveTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Heading-M', {
        size: isMobile ? 16 : 18,
        style: { bold: true, italic: false, underline: false },
      })({ textPresets }),
  },
  headerFiveTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerSixTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Heading-S', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  headerSixTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  paragraphTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Body-M', { size: isMobile ? 14 : 16 })({ textPresets }),
  },
  paragraphTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  quoteTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam('Body-M', { size: isMobile ? 14 : 16 })({ textPresets }),
  },
  quoteTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
